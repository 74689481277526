<template>
  <div>
    <!--篩選區塊-->
    <el-row>
      <el-button style="float: left; margin: 7px" size="mini" type="primary" icon="el-icon-plus" @click="addRFID()">
        新增卡片
      </el-button>
      <div style="float: right;">
        <el-select v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')"
          class="filter-item-mid" v-if="serviceList.length > 0">
          <el-option key="0" label="所有營運商" value="0"></el-option>
          <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'"
            :value="item.id" />
        </el-select>
        <el-select v-model="listQuery.enabled" size="small" class="filter-item">
          <el-option label="所有狀態" :value="undefined" />
          <el-option v-bind:label="$t('Enable')" :value="true" />
          <el-option v-bind:label="$t('Disable')" :value="false" />
        </el-select>
        <el-input size="small" v-model="listQuery.token" placeholder="卡號" class="filter-item" clearable style="width: 180px;"/>
      </div>
    </el-row>
    <el-row>
      <el-table :data="rfid_Table" v-loading="listLoading" style="width: 100%;"
        :cell-style="{ padding: '0', height: '40px' }" @sort-change="tableSortChange">
        <el-table-column align="center" prop="rfid_token" label="卡號" :show-overflow-tooltip="true" sortable >
          <template slot-scope="scope">
              {{ scope.row.rfidToken }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="user_defined_name" label="自訂名稱" :show-overflow-tooltip="true" sortable >
          <template slot-scope="scope">
              {{ scope.row.userDefinedName }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="service_id" label="營運商" :show-overflow-tooltip="true" sortable v-if="serviceList.length>0">
          <template slot-scope="scope">
            {{ formatServiceName(scope.row.serviceId) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="is_enabled" label="啟用狀態" :show-overflow-tooltip="true" sortable>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.enabled" type="danger"> {{ $t('Yes') }} </el-tag>
            <el-tag v-else type="success"> {{ $t('No') }} </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="update_time" label="更新日期" :show-overflow-tooltip="true" sortable >
          <template slot-scope="scope">
              {{ scope.row.updateTime }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="可用充電樁清單" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-button align="center" type="success" size="mini" icon="el-icon-search"
              @click="loadAuthItems(scope.row)">查詢</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="修改" width="65px">
          <template slot-scope="scope">
            <el-button type="success" size="mini" icon="el-icon-edit" @click="modifyRFID(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column align="center" label="刪除" width="65px">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" icon="el-icon-delete"
              @click="handleRfidDelete(scope.$index, scope.row)" />
          </template>
        </el-table-column>
        <!--<el-table-column  align="center" label="新增">
             <template slot-scope="scope">
                 <el-button  type="success" size="mini" icon="el-icon-plus"  @click="addRFID(scope.row)"/>
             </template>
           </el-table-column>-->
      </el-table>
      <!--分頁組件-->
      <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size"
        @pagination="getList" />
      <RfidSetting :dialogSettingVisible="dialogRfidSettingVisible" :rfidData="rfidData" :serviceList="serviceList"
        @close="dialogSettingOnClose()" />
      <RfidAuthSetting :dialogSettingVisible="dialogRfidAuthSettingVisible" :rfidData="rfidData"
        @close="dialogSettingOnClose()" />
    </el-row>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import RfidSetting from './RfidSetting.vue'
import RfidAuthSetting from './RfidAuthSetting.vue'
import { refreshToken, stopRefreshToken } from "@/utils/auth";
export default {
  name: 'RFIDCard',
  components: {
    Pagination,
    RfidSetting,
    RfidAuthSetting
  },
  mixins: [mixins],
  data() {
    return {

      point_Table: [],

      serviceList: [],
      serviceMap: new Map(),
      listLoading: false,
      rfid_Table: [],
      dialogWidth: 0,
      total: 0,
      listQuery: {
        serviceId: "0",
        enabled: undefined,
        token: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15,
        sort: 'create_time,Desc'
      },
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      rfidData: {},
      dialogRfidSettingVisible: false,
      dialogRfidAuthSettingVisible: false
    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.serviceId': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },

    'listQuery.enabled': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },

    'listQuery.token': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
  },
  async created() {
    //this.loginAccount = this.$store.state.currentAccount.name
    //console.log("loginAccount :" + this.loginAccount);
    this.getServiceOptions();
    this.resetPage(this.getList)
    //refreshToken()
  },
  beforeDestroy() {
    //stopRefreshToken()
  },

  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {

    //取得 RFID 列表
    getList() {
      this.listLoading = true
      this.axios.get('/api/v1/rfid/card', {params: this.listQuery}).then(res=> {
        this.rfid_Table = res.data.content
        this.total = res.data.totalElements
        this.listLoading = false
      })
    },
    getServiceOptions() {
      this.listLoading = true
      this.axios.get('/api/v1/common/service/enabled').then(res => {
        this.serviceList = res.data
        this.serviceList.forEach(async (service) => {
          this.serviceMap.set(service.id, service.name)
        });
      })
    },
    selectall() {
      console.log("selectall");
      this.scope.row.enabled = true
    },

    modifyRFID(row) {
      this.rfidData = row
      this.dialogRfidSettingVisible = true
    },

    addRFID() {
      this.rfidData = undefined
      this.dialogRfidSettingVisible = true
    },

    loadAuthItems(row) {
      this.rfidData = row
      this.dialogRfidAuthSettingVisible = true
    },

    // 刪除
    handleRfidDelete(index, row) {
      this.$confirm('提示', {
        message: '確定刪除RFID卡片設定 - ' + row.userDefinedName + '('+ row.rfidToken +') ?',
        confirmButtonText: '確認',
        confirmButtonType: 'dnager',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRFID(index, row.serviceId, row.rfidToken)
      }).catch(()=> {
        console.log('cancel delete RFID')
      })
    },

    async deleteRFID(index, serviceId, rfidToken) {
      this.axios.delete('/api/v1/rfid/card/' + rfidToken, { params:  { serviceId: serviceId, rfidToken: rfidToken }}).then(()=> {
        this.$delete(this.rfid_Table, index);
        this.$message({showClose: true,message: '刪除成功',type: 'success'})
      }).catch(err => {
        this.$message({message: '刪除失敗',type: 'error'})
      })
    },

    dialogSettingOnClose() {
      this.dialogRfidSettingVisible = false
      this.dialogRfidAuthSettingVisible = false
      this.rfidData = undefined
      this.resetPage(this.getList)
    },

    formatServiceName(serviceId) {
      return this.serviceMap.get(serviceId)
    }

  }
}

</script>
