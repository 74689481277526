<template>
    <el-dialog width="420px"  title="可用充電站(樁)清單" :visible="dialogSettingVisible" @close="dialogSettingOnClose()" >
        <el-form ref="dataForm" :model="updateQuery" size="small" >
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                    <el-row :gutter="20" v-loading="treeLoading" style="border: 0px solid #f6f6f6; min-height: 240px; max-height: 400px; padding-left: 20px; padding-right: 20px; overflow-y: auto;">
                        <template>
                            <el-input
                                v-model="filterText"
                                placeholder="搜尋充電樁"
                                clearable
                            />
                            <el-tree
                                ref="treeRef"
                                style="max-width: 600px"
                                :data="treeData"
                                show-checkbox
                                default-expand-all
                                node-key="id"
                                :default-checked-keys=checkedTreeItems
                                :props="defaultProps"
                                :filter-node-method="filterNode"/>
                        </template>
                    </el-row>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <div class="dialog-footer" style="text-align: center;">
                <el-button type="primary" @click="handleUpdate()">儲存</el-button>
                <el-button @click="dialogSettingOnClose()">關閉</el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
import { mixins } from '@/views/common/mixins.js'
import {apiUrl} from "@/utils/main";
export default {
    name: 'RfidAuthSetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        rfidData: {
            require: false,
            default: undefined,
            type: Object
        },
        dialogSettingVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        return {
            updateQuery: {
                serviceId: undefined,
                token: undefined,
                name: undefined,
                enabled: undefined,
                points: []
            },
            
            defaultProps : {
                children: 'children',
                label: 'label',
            },
            treeLoading: false,
            treeData: [],
            checkedTreeItems: [],
            filterText: undefined
        };
    },
    watch: {
        rfidData(val) {
            if (val) {
                this.updateQuery.serviceId = val.serviceId
                this.updateQuery.enabled = val.enabled
                this.updateQuery.token = val.rfidToken
                this.updateQuery.name = val.userDefinedName
                this.getStationPointTree(val.serviceId)
                this.loadAuthItems(val.serviceId, val.rfidToken)
                
            }
        },
        filterText(val) {
            if(val) {
                this.$refs.treeRef.filter(val)
            } else {
                this.$refs.treeRef.filter(undefined)
            }
        }
    },
    async created() {
        this.dialogWidth = this.setDialogWidth()
        this.getStationPointTree("")
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.dialogWidth = this.setDialogWidth()
            })()
        }
    },
    methods: {
        getStationPointTree(serviceId) {
            this.treeLoading = true
            this.axios.get('/api/v1/common/tree/station/enabled', { params:  { serviceId: serviceId}}).then(res => {
                this.treeData = res.data
                this.treeLoading = false
            })
        },
        dialogSettingOnClose() {
            this.treeData = []
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        resetUpdateQuery() {
            this.isModify = false
            this.updateQuery = {
                serviceId: undefined,
                token: undefined,
                name: undefined,
                enabled: true,
                points: []
            }
            this.$refs.treeRef.setCheckedKeys([])
        },
        handleUpdate() {
            
            this.$refs['dataForm'].validate((valid) => {
                
                   //alert(this.$refs.treeRef.getCheckedKeys(true))
                   this.updateQuery.points = this.$refs.treeRef.getCheckedKeys(true)
                   this.axios.put('/api/v1/rfid/card', this.updateQuery).then(() => {
                        this.$message({
                            showClose: true,
                            message: '修改成功',
                            type: 'success'
                        })
                        this.dialogSettingOnClose()
                    }).catch(err => {
                        console.log('err:' + err)
                        this.$message({
                            message: '修改失敗',
                            type: 'error'
                        })
                    })
                
            })
           
        },
        loadAuthItems(serviceId, rfidToken) {
            this.treeLoading = true
            this.axios.get("/api/v1/rfid/card/"+ rfidToken+"/auth", { params:  { serviceId: serviceId, rfidToken: rfidToken }}).then(res => {
                this.checkedTreeItems = res.data
                this.treeLoading = false
            })
        },
        filterNode(value, data) {
            if(!value) return true
            return data.label.indexOf(value) !== -1
        }

    }
}
</script>
<style>

.el-dialog__body {
    padding-top: 5px;
}
</style>