<template>
    <el-dialog width="600" title="卡片設定" :visible="dialogSettingVisible" @close="dialogSettingOnClose()">
        <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small">
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-divider content-position="left"><span>卡片資訊設定</span></el-divider>
                    <el-form-item prop="serviceId" v-bind:label="$t('Operator')" v-if="serviceList.length > 0 && !this.isModify">
                        <el-select id="serviceId" v-model="updateQuery.serviceId" :disabled="this.isModify" size="small" class="filter-item custom-width" >
                            <el-option v-for="item in this.serviceList" :label="item.name" :key="item.id" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="token" label="卡號" >
                        <el-input id="token" size="small" v-model="updateQuery.token" placeholder="請輸入RFID卡片Token號碼"
                            class="filter-item custom-width" :disabled="isModify" clearable />
                    </el-form-item>
                    <el-form-item prop="name" label="名稱" >
                        <el-input id="name" size="small" v-model="updateQuery.name" placeholder="請輸入自訂名稱"
                            class="filter-item custom-width" clearable />
                    </el-form-item>
                    <el-form-item prop="enabled" v-bind:label="$t('EnableStatus')" >
                        <el-select id="enabled" v-model="updateQuery.enabled" size="small" class="filter-item custom-width" >
                            <el-option v-bind:label="$t('Enable')" :value="true" />
                            <el-option v-bind:label="$t('Disable')" :value="false" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-divider content-position="left"><span>可用充電站(樁)設定</span></el-divider>
                    <el-row :gutter="20" v-loading="treeLoading"
                        style="border: 0px solid #f6f6f6; min-height: 240px; max-height: 400px; padding-left: 20px; padding-right: 20px; overflow-y: auto;">
                        <template>
                            <el-tree ref="treeRef" style="max-width: 600px" :data="treeData" show-checkbox node-key="id"
                                :default-checked-keys=checkedTreeItems :props="defaultProps" />
                        </template>
                    </el-row>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <div class="dialog-footer" style="text-align: center;">
                <el-button type="primary" @click="handleUpdate()">儲存</el-button>
                <el-button @click="dialogSettingOnClose()">關閉</el-button>
            </div>
        </template>
        <br/><br/>
    </el-dialog>
</template>
<script>
import { mixins } from '@/views/common/mixins.js'
import {apiUrl} from "@/utils/main";
export default {
    name: 'RfidSetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        rfidData: {
            require: false,
            default: undefined,
            type: Object
        },
        serviceList: {
            require: false,
            default: undefined,
            type: Array
        },
        dialogSettingVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        return {
            isModify: false,
            updateQuery: {
                serviceId: undefined,
                token: undefined,
                name: undefined,
                enabled: undefined,
                points: []
            },
            rules: {
                token: [{ required: true, message: '(必填)', trigger: 'blur' }],
                name: [{ required: true, message: '(必填)', trigger: 'blur' }],
                enabled: [{ required: true, message: '(必填)', trigger: 'blur' }],
            },
            defaultProps : {
                children: 'children',
                label: 'label',
            },
            treeLoading: false,
            treeData: [],
            checkedTreeItems: []
        };
    },
    watch: {
        rfidData(val) {
            if (val) {
                this.isModify = true
                this.updateQuery.serviceId = val.serviceId
                this.updateQuery.enabled = val.enabled
                this.updateQuery.token = val.rfidToken
                this.updateQuery.name = val.userDefinedName
                this.getStationPointTree(val.serviceId)
                this.loadAuthItems(val.serviceId, val.rfidToken)
                
            } else{
                this.isModify = false
                this.updateQuery.serviceId = undefined
                this.updateQuery.enabled = undefined
                this.updateQuery.token = undefined
                this.updateQuery.name = undefined
                this.treeData = []
                this.checkedTreeItems = []
                this.getStationPointTree("")
                
            }
        },
        'updateQuery.serviceId': function () {
            this.getStationPointTree(this.updateQuery.serviceId)
        },
    },
    async created() {
        this.dialogWidth = this.setDialogWidth()
        this.getStationPointTree("")
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.dialogWidth = this.setDialogWidth()
            })()
        }
    },
    methods: {
        getStationPointTree(serviceId) {
            if (serviceId == undefined || serviceId == "") { 
                serviceId = this.$store.state.currentAccount.serviceInfo.id 
                this.updateQuery.serviceId = serviceId
            }
            this.treeLoading = true
            this.axios.get('/api/v1/common/tree/station/enabled', { params:  { serviceId: serviceId}}).then(res => {
                this.treeData = res.data
                this.treeLoading = false
            })
        },
        dialogSettingOnClose() {
            this.treeData = []
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        resetUpdateQuery() {
            this.isModify = false
            this.updateQuery = {
                serviceId: undefined,
                token: undefined,
                name: undefined,
                enabled: true,
                points: []
            }
            this.$refs.treeRef.setCheckedKeys([])
        },
        handleUpdate() {
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                   //alert(this.$refs.treeRef.getCheckedKeys(true))
                   this.updateQuery.points = this.$refs.treeRef.getCheckedKeys(true)
                   this.axios.put('/api/v1/rfid/card', this.updateQuery).then(() => {
                        this.$message({
                            showClose: true,
                            message: '修改成功',
                            type: 'success'
                        })
                        this.dialogSettingOnClose()
                    }).catch(err => {
                        console.log('err:' + err)
                        this.$message({
                            message: '修改失敗',
                            type: 'error'
                        })
                    })
                } else {
                    this.$message({
                        message: '輸入欄位格式不正確',
                        type: 'error'
                    })
                }
            })
        },
        loadAuthItems(serviceId, rfidToken) {
            this.treeLoading = true
            this.axios.get("/api/v1/rfid/card/"+ rfidToken+"/auth", { params:  { serviceId: serviceId, rfidToken: rfidToken }}).then(res => {
                this.checkedTreeItems = res.data
                this.treeLoading = false
            })
        },

    }
}
</script>
<style>
.el-dialog__body {
    padding-top: 5px;
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) { 
    .el-form-item__content {
        text-align: left;
    }
    
}

@media (min-width: 768px) { 
    .custom-width {
        width: 200px;
    }

    .el-form-item__content {
        text-align: left;
        margin-left: 120px;
    }

    .el-form-item__label {
        width: 120px;
    }
 }
</style>